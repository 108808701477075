* {
    font-family: 'Nunito', sans-serif;
}

h1 {
    font-size: 24px;
    font-weight: 800;
    margin: 0;
    line-height: 37px;
}

p {
    font-weight: 400;
    margin: 0;
    color: #999;
    font-size: 13px;
}

/* Text Field Styles */
input {
    background-color: #F4F4F4;
    width: calc(100% - 104px);
    height: 16px;
    margin: 0 32px;
    border-radius: 10px;
    border: none;
    padding: 20px;
    margin-bottom: 15px;
    font-weight: 900
}

input[type=checkbox] {
    width: 20px;
    height: 20px;
    color: #2F2E41;
    background-color: #2F2E41;
    border-color: #2F2E41;
    display: inline-block;
    padding: 0;
    margin: 0;
    margin-right: 10px;
}

.input-field {
    margin-top: 20px;
}

.primary-button {
    background-color: #2F2E41;
    color: #fff;
    border-radius: 10px;
    height: 70px;
    width: 300px;
    text-align: center;
    font-weight: 900;
    font-size: 24px;
    border: none;
}

/* Generic anchor tag styles */
a {
    color: #2F2E41;
    text-decoration: none;
    font-weight: 900;
}

.anchor-bottom {
    position: fixed;
    bottom: 30px;
    width: 100%;
}

.unset-content-align {
    display: unset;
    vertical-align: super;
}

.card-container {
    background-color: white;
    border-radius: 18px;
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.1);
}

.small {
    height: 250px;
    width: 173px;
}

.large {
    height: 200px;
    width: calc(100% - 60px);
    margin-left: 30px;
}

.light {
    background-color: #A3A0CA;    
}

.small img {
    width: 100%;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
}

video {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

#success-alert {
    width: 80vw;
    margin-left: calc(10% - 20px);
    border-radius: 10px;
    background-color: #2F2E41;
    color: white;
    padding: 20px;
    font-weight: 500;
    text-align: center;
    z-index: 100000;
    position: absolute;
    left: 0;
    margin-top: 20px;
    top: 120px;
}


#success-alert p {
    font-weight: 700;
}

#video-controls {
    position: absolute;
    width: 100vw;
    height: 168px;
    border-radius: 32px;
    bottom: -32px;
    display: grid;
    background-color: white;
}

#take-picture {
    /* Circle red button */
    width: 72px;
    height: 72px;
    background-color: #FFC0B8;
    border-radius: 50%;
    border: 0;
    margin-top: 30px;
    position: relative;
    left: calc(50% - 36px);
}

#take-picture #inside {
    width: 40px;
    height: 40px;
    background-color: #FF8473;
    border-radius: 50%;
    border: 0;
    margin: 16px
}

#img {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    border-radius: 18px;
    position: absolute;
    top: 0;
    left: 0;
}

#food {
    position: absolute;
    right: 30px;
    width: 100px;
    border: 0;
    background-color: #A3A0CA;
    color: white;
    border-radius: 10px;
    padding: 10px;;
    /* centered vertically */
    top: 48px;
    display: none;
}