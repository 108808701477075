.footerBar {
    background: #FFFFFF;
    width: 100%;
    height: 99px;
}

.connectContainer {
    margin-top: 90px;
}

.footerButtonContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    
}

.footerButtonContainer button {
    background-color: transparent;
    border: none;
    margin-top: 30px;
}

.camera {
    font-size: 1.5rem;
    color: white;
}

.home {
    font-size: 1.5rem;
    color: white;
}

.personal {
    font-size: 1.5rem;
    color: white;
}

.settings {
    font-size: 1.5rem;
    color: white;
}